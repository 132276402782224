'use client';

import { useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Mail } from 'lucide-react';

// Validation schema for the newsletter form
const formSchema = z.object({
  email: z.string().email({
    message: 'Please enter a valid email address.',
  }),
  firstName: z.string().min(1, {
    message: 'Please enter your first name.',
  }),
});

type FormValues = z.infer<typeof formSchema>;

interface NewsletterSignupProps {
  source?: string;
  className?: string;
  layout?: 'horizontal' | 'vertical';
  buttonText?: string;
  showName?: boolean;
  darkMode?: boolean;
}

export function NewsletterSignup({
  source = 'website',
  className = '',
  layout = 'horizontal',
  buttonText = 'Subscribe',
  showName = true,
  darkMode = false,
}: NewsletterSignupProps) {
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [message, setMessage] = useState('');

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      firstName: '',
    },
  });

  const onSubmit = async (data: FormValues) => {
    setStatus('loading');
    
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          firstName: data.firstName,
          source,
        }),
      });

      const result = await response.json();

      if (result.success) {
        setStatus('success');
        setMessage(result.alreadySubscribed 
          ? 'You\'re already subscribed to our newsletter!' 
          : 'Thanks for subscribing! Check your inbox for a welcome email.');
        form.reset();
      } else {
        setStatus('error');
        setMessage(result.message || 'Something went wrong. Please try again.');
      }
    } catch (error) {
      setStatus('error');
      setMessage('Unable to subscribe. Please try again later.');
      console.error('Newsletter subscription error:', error);
    }
  };

  const isHorizontal = layout === 'horizontal';
  
  return (
    <div className={`${className} ${darkMode ? 'text-white' : ''}`}>
      {status === 'success' ? (
        <div className="bg-green-50 dark:bg-green-900/20 border border-green-200 dark:border-green-800 rounded-lg p-4 text-center">
          <p className={`text-green-800 dark:text-green-400 font-medium`}>{message}</p>
        </div>
      ) : (
        <>
          <Form {...form}>
            <form 
              onSubmit={form.handleSubmit(onSubmit)} 
              className={`flex ${isHorizontal ? 'flex-row flex-wrap' : 'flex-col'} gap-3`}
            >
              {showName && (
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className={isHorizontal ? 'flex-1 min-w-[160px]' : 'w-full'}>
                      <FormControl>
                        <Input 
                          placeholder="First Name" 
                          {...field} 
                          className={`${darkMode ? 'bg-background/10 border-muted/30 placeholder:text-muted-foreground/50' : ''}`}
                          disabled={status === 'loading'}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
              
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className={isHorizontal ? 'flex-[2] min-w-[200px]' : 'w-full'}>
                    <FormControl>
                      <Input 
                        type="email" 
                        placeholder="Email Address" 
                        {...field} 
                        className={`${darkMode ? 'bg-background/10 border-muted/30 placeholder:text-muted-foreground/50' : ''}`}
                        disabled={status === 'loading'}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <Button 
                type="submit" 
                className={`${isHorizontal ? 'min-w-[120px]' : 'w-full'}`}
                disabled={status === 'loading'}
              >
                {status === 'loading' ? (
                  <span className="flex items-center gap-2">
                    <svg className="animate-spin h-4 w-4 text-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>Subscribing...</span>
                  </span>
                ) : (
                  <span className="flex items-center gap-2">
                    <Mail className="h-4 w-4" />
                    {buttonText}
                  </span>
                )}
              </Button>
            </form>
          </Form>
          
          {status === 'error' && (
            <p className="text-destructive text-sm mt-2">
              {message}
            </p>
          )}
          
          <p className="text-xs text-muted-foreground mt-2">
            Get study abroad tips, application guidance, and scholarship opportunities. We respect your privacy and will never share your information.
          </p>
        </>
      )}
    </div>
  );
} 